var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: "fill-current  text-".concat(_vm.color),
    attrs: {
      "version": "1.0",
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "".concat(_vm.height, "px"),
      "width": "".concat(_vm.width, "px"),
      "viewBox": "0 0 64.000000 64.000000",
      "preserveAspectRatio": "xMidYMid meet"
    }
  }, [_c('g', {
    attrs: {
      "transform": "translate(0.000000,64.000000) scale(0.100000,-0.100000)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M191 606 c-49 -19 -71 -50 -71 -102 0 -23 -4 -46 -10 -49 -15 -9 -12\n-61 3 -77 17 -16 37 -53 37 -67 0 -5 -25 -24 -56 -42 -76 -43 -95 -76 -92\n-159 l3 -65 143 -3 c129 -2 142 -4 140 -20 -3 -18 -1 -18 82 -7 42 5 55 14\n152 113 58 59 108 114 110 122 13 38 -63 116 -101 103 -9 -3 -35 -24 -57 -45\n-41 -40 -42 -40 -73 -24 -17 9 -31 21 -31 27 0 14 20 50 37 67 17 17 16 62 -2\n86 -13 17 -13 21 0 30 13 10 13 15 -2 45 -10 19 -30 44 -45 54 -33 25 -119 31\n-167 13z m146 -26 c39 -23 61 -59 43 -70 -14 -9 -4 -70 11 -70 13 0 11 -36 -3\n-52 -7 -8 -23 -32 -34 -54 -12 -22 -37 -48 -58 -59 l-36 -20 -36 20 c-21 11\n-46 37 -58 59 -11 22 -27 46 -33 54 -15 16 -17 52 -3 52 6 0 10 24 10 53 0 64\n6 73 55 91 56 20 105 19 142 -4z m262 -286 c15 -20 18 -31 11 -45 -17 -31 -25\n-29 -69 15 l-43 44 23 15 c26 19 47 11 78 -29z m-339 -54 c14 0 41 11 60 24\n32 22 37 23 63 10 15 -8 27 -18 27 -22 0 -4 -24 -32 -54 -61 -41 -41 -56 -64\n-61 -93 l-7 -38 -135 0 -135 0 4 64 c4 75 16 92 92 135 l50 29 35 -24 c20 -13\n47 -24 61 -24z m303 -43 c-3 -5 -25 10 -49 34 -24 23 -41 46 -37 52 3 5 25\n-10 49 -34 24 -23 41 -46 37 -52z m-80 -79 c-32 -32 -61 -58 -65 -58 -4 0 -8\n9 -8 20 0 11 -7 20 -15 20 -23 0 -18 13 15 45 16 15 27 32 24 37 -4 6 -21 -4\n-38 -21 -30 -29 -56 -40 -56 -24 0 4 26 33 57 65 l57 58 43 -42 43 -42 -57\n-58z m-112 -29 c24 -24 22 -52 -4 -57 -22 -5 -57 25 -57 48 0 36 30 40 61 9z"
    }
  }), _c('path', {
    attrs: {
      "d": "M180 519 c-14 -24 -3 -42 11 -18 8 15 13 15 46 3 45 -16 103 -18 103\n-4 0 6 -14 10 -30 10 -17 0 -47 7 -67 15 -46 19 -50 19 -63 -6z"
    }
  }), _c('path', {
    attrs: {
      "d": "M170 232 c0 -4 20 -18 45 -30 l45 -22 45 22 c25 12 45 26 45 30 0 12\n-7 10 -50 -12 l-40 -20 -40 20 c-43 22 -50 24 -50 12z"
    }
  }), _c('path', {
    attrs: {
      "d": "M50 100 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0\n-10 -9 -10 -20z"
    }
  }), _c('path', {
    attrs: {
      "d": "M100 100 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0\n-10 -9 -10 -20z"
    }
  }), _c('path', {
    attrs: {
      "d": "M150 100 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0\n-10 -9 -10 -20z"
    }
  }), _c('path', {
    attrs: {
      "d": "M200 100 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0\n-10 -9 -10 -20z"
    }
  }), _c('path', {
    attrs: {
      "d": "M250 100 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0\n-10 -9 -10 -20z"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }